h1 {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0;
}

p {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

li {
  margin-top: 3%;
}

.spacer {
  margin-top: 10%;
}
