p {
  padding: 2%;
}

.site-layout-content {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  margin-top: -5%;
  margin-bottom: 3%;
  height: 60%;
  padding-top: 2%;
  padding-bottom: 0%;
}

.site-layout-content-pitch-card {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  margin-top: -10%;
  margin-bottom: 3%;
  height: 60%;
  width: 100%;
  padding-top: 5%;
}

.site-layout-content-credentials-card {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  margin-top: -5%;
  margin-bottom: 3%;
  height: 60%;
  width: 100%;
  padding: 3%;
}

.site-layout-content-services-card {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  margin-top: -15%;
  margin-bottom: 3%;
  height: 60%;
  width: 100%;
  padding-top: 5%;
}

.site-layout-content-contact-card {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  margin-top: -5%;
  margin-bottom: 3%;
  height: 60%;
  width: 100%;
  padding-top: 5%;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

/* The animation code */
@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
