h1 {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0;
}

p {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.site-layout-content-about-us-card {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
  margin-top: -4%;
  animation-name: fadeIn;
  animation-duration: 0.7s;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.7s;
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
