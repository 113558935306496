@import "~antd/dist/antd.css";

svg {
  color: "white";
  width: 1.8em;
  height: 1.8em;
  margin-top: 1.5em;
}

.site-layout-content {
  background-color: #990033;
  background: #fff;
}

.layout {
  height: 100%;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-menu-title-content {
  font-size: 14px;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  background-color: #990033 !important;
}

.ant-menu-item-active {
  background-color: #990033 !important;
}

.ant-menu-item-selected {
  background-color: #990033 !important;
}

.ant-menu-vertical {
  background-color: #990033 !important;
}

.ant-menu-submenu {
  background-color: #990033 !important;
}

.ant-menu-dark .ant-menu-item:focus-visible {
  box-shadow: none !important;
}

.ant-menu-submenu-popup {
  /* this one controls the submenu */
  top: 64px !important;
  left: 0px !important;
  width: 100%;
}

/* disable animations for mobile */
@media screen and (max-width: 480px) {
  .ant-menu-item:hover .ant-menu-title-content {
    color: orange;
    animation: none !important;
  }
  .ant-menu-item .ant-menu-title-content {
    animation: none !important;
  }
}

.ant-menu-item:hover .ant-menu-title-content {
  animation: increaseFontSize 0.3s;
  animation-fill-mode: forwards;
}

.ant-menu-item .ant-menu-title-content {
  background-color: #990033 !important;
  animation: decreaseFontSize 0.3s;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes increaseFontSize {
  from {
    font-size: 14px;
  }

  to {
    font-size: 16px;
  }
}

@keyframes decreaseFontSize {
  from {
    font-size: 16px;
  }

  to {
    font-size: 14px;
  }
}
