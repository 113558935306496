h1 {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0;
}

p {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.site-layout-content-client-list-card {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
  animation-name: fadeIn3;
  animation-duration: 0.7s;
  margin-top: -10%;
  margin-bottom: 20%;
  height: 60%;
  width: 100%;
  padding-top: 5%;
}

.spacer {
  margin-top: 10%;
}

.fadeIn3 {
  animation-name: fadeIn2;
  animation-duration: 1.5s;
}

@keyframes fadeIn3 {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
