h1 {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0;
}

p {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.site-layout-content-dir-card-mobile {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
  animation-name: fadeIn;
  animation-duration: 0.7s;
  margin-top: 3%;
  padding-top: 0%;
}

.site-layout-content-dir-card {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
  animation-name: fadeIn;
  animation-duration: 0.7s;
  margin-top: -4.5%;
  padding-top: 0%;
}

.fadeIn2 {
  animation-name: fadeIn2;
  animation-duration: 1.5s;
}

@keyframes fadeIn2 {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
